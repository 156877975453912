import { updateField } from 'vuex-map-fields';
import moment from 'moment';
export default {
    updateField,
    isLoading : (state,payload) => {
        state.loading = payload;  
    },
    isChartLoading : (state,payload) => {
        state.chartLoading = payload;  
    },
    isChartDataLoading : (state,payload) => {
        state.chartDataLoading = payload;     
    },
    subResult : (state,payload) => {
        let index = payload.index;
        state.index = index;
        if(index == 0){
            state.sub.battery.find.list = payload.list; 
            state.sub.battery.find.totalCount = payload.total;
            state.sub.battery.find.currentPage = payload.pageNum;
        }
        else if(index == 1) {
            state.sub.battery.register.fileInfo = null;
            state.sub.battery.register.ymd = null;
        }
        else if(index == 2) {
            state.sub.repair.find.list = payload.list; 
        }
        else if(index == 3) {
            state.sub.repair.register.shopName = '';
            state.sub.repair.register.list = []; 
        }
    },
    resetFile : (state,payload) => {
        state.sub.battery.register.fileInfo = null;
        state.sub.battery.register.ymd = null;
    },
    resetRepair : (state,payload) => {
        state.sub.repair.register.shopName = '';
        state.sub.repair.register.list = [];
    },
    resetSubInfo : (state,payload) => {
        // 하단 상세 영역 숨기기
        state.index = -1;
        // 배터리, 차량정보 초기화
        state.result = {
            car : {
                id : '',
                vin : '',
                firstPrice : '',
                yyyy : '',
                cc : '',
                brandName : '',
                fuel : '',
                carName : '',
                misstion : '',
                carNameDetail : '',
            },
            battery : {
                battery_capacity : '',
                battery_type : '',
                cruising_distance : '',
                combined_fuel_economy_electric : '',
                city_fuel_economy_electricity : '',
                high_speed_fuel_economy_electric : ''
            }
        };
        // 배터리 상태정보 목록 초기화
        state.sub.battery.find.list = [];
        state.sub.battery.find.perPage = 10;
        state.sub.battery.find.currentPage = 1;
        state.sub.battery.find.totalCount = 0;
        // 모달 상세 초기화
        state.sub.battery.find.modal.detail.list = [];
        state.sub.battery.find.modal.detail.perPage = 10;
        state.sub.battery.find.modal.detail.currentPage = 1;
        state.sub.battery.find.modal.detail.totalCount = 0;
        // 모달 차트 초기화
        state.sub.battery.find.modal.chart.series = [];
        state.sub.battery.find.modal.chart.hh = null;
        state.sub.battery.find.modal.chart.hhList = [];
        // 배터리 상태 정보 등록 초기화
        state.sub.battery.register.fileInfo = null;
        state.sub.battery.register.ymd = null;
        // 수리/정비이력 목록 초기화
        state.sub.repair.find.list = [];
        state.sub.repair.find.perPage = 10;
        state.sub.repair.find.currentPage = 1;
        state.sub.repair.find.totalCount = 0;
        // 모달 상세 초기화
        state.sub.repair.find.modal.detail.list = [];
        // 모달 상세 총합계 초기화
        state.sub.repair.find.modal.detail.sum[0]['price'] = 0;
        // 수리/정비이력 초기화
        state.sub.repair.register.shopName = '';
        state.sub.repair.register.list = [];

    },
    findCarBatteryInfo : (state,payload) => {
        let carInfo = {
            id : payload.id,
            // 차대번호 - vin
            vin : payload.vin,
            // 출고가격  - firstPrice
            firstPrice : payload.firstPrice,
            // 연식 - yyyy
            yyyy : payload.yyyy,
            // 배기량 - cc
            cc : payload.cc,
            // 제조사 - brandName
            brandName : payload.brandName,
            // 연료 - fuel
            fuel : payload.fuel,
            // 차량명 - carName
            carName : payload.carName,
            // 미션 - misstion
            misstion : payload.misstion,
            // 차량세부명칭 - carNameDetail
            carNameDetail : payload.carNameDetail,
        }

        let batteryInfo = {}

        let battery = payload.evbattery;
        if(battery != undefined && battery != null && battery.length > 0){
            for(let i = 0; i < battery.length; i++) {
                // 배터리 용량
                if(battery[i]['kindName'] == 'battery_capacity') {
                    batteryInfo.battery_capacity = battery[i]['value'];
                }
                // 배터리 종류
                else if(battery[i]['kindName'] == 'battery_type') {
                    batteryInfo.battery_type = battery[i]['value'];
                }
                // 항속거리
                else if(battery[i]['kindName'] == 'cruising_distance') {
                    batteryInfo.cruising_distance = battery[i]['value'];
                }
                // 복합연비 
                else if(battery[i]['kindName'] == 'combined_fuel_economy_electric') {
                    batteryInfo.combined_fuel_economy_electric = battery[i]['value'];
                }
                // 도심연비  
                else if(battery[i]['kindName'] == 'city_fuel_economy_electricity') {
                    batteryInfo.city_fuel_economy_electricity = battery[i]['value'];
                }
                // 고속연비  
                else if(battery[i]['kindName'] == 'high_speed_fuel_economy_electric') {
                    batteryInfo.high_speed_fuel_economy_electric = battery[i]['value'];
                }
            }
        }

        state.result.car = carInfo;
        state.result.battery = batteryInfo;
    },
    findBatteryInfoDetail : (state,payload) => {
        state.sub.battery.find.modal.detail.list = payload.detailInfo;
        state.sub.battery.find.modal.returnHash = payload.returnHash;
        state.sub.battery.find.modal.blockNumber = payload.blockNumber;
        state.sub.battery.find.modal.blockDt = payload.blockDt;
        payload.me.$bvModal.show(payload.modalId);
    },
    findBatteryInfoDetailHour : (state,payload) => {
        state.sub.battery.find.modal.chart.hhList = payload.hhList;
        payload.me.$bvModal.show(payload.modalId);
    },
    findBatteryInfoChart : (state,payload) => {
        payload.me.$refs.chart1.updateOptions({
            series: payload.chartData.series,
            xaxis: {
                categories : payload.chartData.categories
            }
        })
        payload.me.$refs.chart2.updateOptions({
            series: payload.chartData.series,
            chart: {
                selection: {
                    xaxis : {
                        min : payload.chartData.min,
                        max : payload.chartData.max,
                    }
                },
            },
            xaxis: {
                categories : payload.chartData.categories
            }
        })
        state.chartDataLoading = false; 
    },
    findrRepairInfoDetail : (state,payload) => {
        state.sub.repair.find.modal.detail.list = payload.detailInfo;
        state.sub.repair.find.modal.detail.sum[0] = {
            partName : "합계",
            price : payload.total
        };
        payload.me.$bvModal.show(payload.modalId);
    },
    addRepairItem : (state,payload) =>{
        state.sub.repair.register.list.push({
            partName : '',
            price : ''
        })
    },
    removeRepairItem : (state,payload) => {
        state.sub.repair.register.list.splice(payload.index,1);
    },
    resetInfo : (state,payload) => {
        state.search = {
            vhcleNumber : '',
            owerName : ''
        };
        state.result = {
            car : {
                id : '',
                vin : '',
                firstPrice : '',
                yyyy : '',
                cc : '',
                brandName : '',
                fuel : '',
                carName : '',
                misstion : '',
                carNameDetail : '',
            },
            battery : {
                battery_capacity : '',
                battery_type : '',
                cruising_distance : '',
                combined_fuel_economy_electric : '',
                city_fuel_economy_electricity : '',
                high_speed_fuel_economy_electric : ''
            }
        };
        state.index = -1;
    }
}