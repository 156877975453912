// import chartData from '@/ChartData'
import { $themeColors } from '@themeConfig'
import moment from 'moment';
export default {
    loading : false,
    chartLoading : false,
    chartDataLoading : false,
    index : -1,
    buttonInfo :  [
        {
            text : '배터리 상태 정보 보기',
            route_name : 'battery_view'
        },
        {
            text : '배터리 상태 정보 등록',
            route_name : 'battery_regist'
        },
        {
            text : '수리/정비 이력 보기',
            route_name : 'repair_view'
        },
        {
            text : '수리/정비 이력 등록',
            route_name : 'repair_regist'
        },
    ],
    search : {
        // vhcleNumber : '49호7887',
        // owerName : '롯데렌터카'
        vhcleNumber : '',
        owerName : ''
    },
    result : {
        car : {
            id : '',
            // 차대번호 - vin
            vin : '',
            // 출고가격  - firstPrice
            firstPrice : '',
            // 연식 - yyyy
            yyyy : '',
            // 배기량 - cc
            cc : '',
            // 제조사 - brandName
            brandName : '',
            // 연료 - fuel
            fuel : '',
            // 차량명 - carName
            carName : '',
            // 미션 - misstion
            misstion : '',
            // 차량세부명칭 - carNameDetail
            carNameDetail : '',
        },
        battery : {
            // 배터리용량
            battery_capacity : '',
            // 배터리종류
            battery_type : '',
            // 항속거리
            cruising_distance : '',
            // 복합연비
            combined_fuel_economy_electric : '',
            // 도심연비
            city_fuel_economy_electricity : '',
            // 고속연비
            high_speed_fuel_economy_electric : ''
        }
    },
    sub : {
        battery : {
            find : {
                fields : [{
                    key : 'createDt',
                    thClass : "text-center",
                },{
                    key : 'detail',
                    thClass : "text-center",
                    tdClass: 'w10'
                }],
                list : [],
                perPage : 10,
                currentPage : 1,
                totalCount : 0,
                modal : {
                    detail : {
                        fields : [{
                            key : 'pid',
                            label : '항목',
                            thClass : "text-center  p-1",
                        },{
                            key : 'detail',
                            label : '상세보기',
                            thClass : "text-center p-1",
                            tdClass: 'w10 text-center p-1'
                        }],
                        list : [],
                        perPage : 10,
                        currentPage : 1,
                        totalCount : 0,
                    },
                    returnHash : '',
                    blockNumber : '',
                    blockDt : '',
                    chart : {
                        hh : null,
                        hhList : [],
                        series : [],
                        options: {
                            noData : {
                                text : '시간을 선택하세요'
                            },
                            chart: {
                              id: 'chart1',
                              type: 'line',
                              height: 400,
                              toolbar: {
                                autoSelected: 'pan',
                                show: false
                              }
                            },
                            tooltip: {
                                custom(data) {
                                  return `<div class="px-1 py-50"><span>${data.series[data.seriesIndex][data.dataPointIndex]}</span></div>`
                                },
                            },
                            colors: ['#546E7A'],
                            stroke: {
                                width: 3
                            },
                            dataLabels: {
                                enabled: false
                            },
                            fill: {
                                opacity: 1,
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                              type: 'datetime',
                              title: {
                                text: '시간'
                              },
                              tooltip: {
                                formatter: function(val) {
                                  return moment(val).subtract(9, 'hours').format("HH:mm:ss");
                                }
                              }
                            },
                            yaxis: {
                              title: {
                                text: '수치'
                              }
                            },
                        },
                        seriesLine : [],
                        optionsLine : {
                            noData : {
                                text : '시간을 선택하세요'
                            },
                            chart: {
                                id: 'chart2',
                                height: 130,
                                type: 'area',
                                brush:{
                                    target: 'chart1',
                                    enabled: true
                                },
                                selection: {
                                    enabled: true,
                                    xaxis : {}
                                },
                            },
                            colors: ['#008FFB'],
                            fill: {
                            type: 'gradient',
                            gradient: {
                                opacityFrom: 0.91,
                                opacityTo: 0.1,
                            }
                            },
                            xaxis: {
                                type: 'datetime',
                                tooltip: {
                                    enabled: false
                                }
                            },
                            yaxis: {
                            tickAmount: 2
                            }
                        }
                    }
                }
            },
            register : {
                ymd : null,
                fileInfo : null
            }
        },
        repair : {
            find : {
                fields : [{
                    key : 'createDt',
                    thClass : "text-center",
                },{
                    key : 'shopName',
                    thClass : "text-center",
                },{
                    key : 'title',
                    thClass : "text-center",
                },{
                    key : 'totalPrice',
                    thClass : "text-center",
                },{
                    key : 'detail',
                    thClass : "text-center",
                    tdClass: 'w10'
                }],
                list :  [],
                perPage : 10,
                currentPage : 1,
                totalCount : 0,
                modal : {
                    detail : {
                        fields : [{
                            key : 'partName',
                            tdClass: 'p-1'
                        },{
                            key : 'price',
                            tdClass: 'w10 p-1'
                        }],
                        list : [],
                        sum : [{
                            createDt : '합계',
                            price : 0
                        }]
                    }
                } 
            },
            register : {
                shopName : '',
                fields : [{
                    key : 'partName',
                    label : '부품명',
                    thClass : "text-center p-1",
                    tdClass: 'w75 pt-1 pr-2'
                },{
                    key : 'price',
                    label : '가격',
                    thClass : "text-center p-1",
                    tdClass: 'w25 pt-1 pr-2'
                },{
                    key : 'remove',
                    label : '',
                    tdClass : 'text-center pt-1 '
                }],
                list : []
            }
        }
    }
}