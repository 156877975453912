import state from './state'
import actions from './actions'
import mutations from './mutations'
import { getField } from 'vuex-map-fields';
export default {
    namespaced: true,
    state : state,
    actions : actions,
    mutations : mutations,
    getters : {
        getField
    }
}