import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import main from './main/main'

Vue.use(VueRouter)

const userData = getUserData();

let redirectURL = userData && userData.role == 'user' ? 'main' : 'login';

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
            path: '/login',
            name: 'login',
            component: () =>
                import ('@/views/Login.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
        },
        {
            path: '/not-authorized',
            name: 'not-authorized',
            component: () =>
                import ('@/views/error/NotAuthorized.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
            },
        },
        { path: '/', redirect: { name: redirectURL } },
        ...main,
        {
            path: '*',
            redirect: 'error-404',
        }
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()
    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) {
            return next({ name: 'login' })
        }

        // If logged in => not authorized
        return next({ name: 'not-authorized' })
    }
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    return next()
})

export default router