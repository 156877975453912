import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios';
import Constant from '@/Constant';
import useJwt from '@/auth/jwt/useJwt'
import { _ } from '@/libs/acl/config';
let BASEURL = Constant.BASEURL;
import moment from 'moment';

export default {
    // index에 따른 api 호출 후, 화면 표시
    subResult : (store,payload) => {
        store.commit("isLoading",true);
        const headers =  {
            'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        }

        let pageNum = 1;
        if(payload.paging != undefined && payload.paging != null && payload.paging != "") {
            pageNum = payload.paging;
        }
        if(payload.index == 0) {
            let param = {
                carId: store.state.result.car.id,
                id: "",
                keyword: "",
                orderBy: "",
                useYn: "Y",
                pageNum: pageNum,
                pageSize: 10
              }
            axios.post(`${BASEURL}/v1/repair/repairmaster/findRepairRepairmasterCarByYmd`,param,{
                headers
            })
            .then(({data})=>{
                store.commit("isLoading",false);
                if(data.data == undefined || data.data == null) {
                    payload.me.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `알림`,
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                          text: data.message
                        },
                      })
                      return;
                }
                // data = data.data;
                let list = [];
                if(data.data != undefined && data.data != null) {
                    for(let i = 0; i<data.data.length; i++) {
                        list.push({
                            id : data.data[i]['id'],
                            createDt : moment(data.data[i]['ymd']).format('YYYY-MM-DD'),
                            returnHash : data.data[i]['returnHash'],
                            blockDt : data.data[i]['blockDt'],
                            blockNumber : data.data[i]['blockNumber'],
                            detail : true     
                        })
                    }
                }

                let pageNum = data.pageNum;
                let pageSize = data.pageSize;
                let pages = data.pages;
                let total = data.total;
                data = {
                    index : payload.index,
                    list : list,
                    pageNum : pageNum,
                    pageSize : pageSize,
                    pages : pages,
                    total : total
                }
                store.commit('subResult',data);
            })
            .catch((err)=> {
                store.commit("isLoading",false);
                console.log("에러 : ", err);
                Vue.prototype.$utils.exception(err);
            })
        }
        else if(payload.index == 1) {
            let data = {
                index : payload.index,
            };
            store.commit("isLoading",false);
            store.commit('subResult',data);
        }
        else if(payload.index == 2) {
            store.commit("isLoading",true);
            let param = {
                carId: store.state.result.car.id,
                shopName: "",
                id: "",
                keyword: "",
                orderBy: "",
                useYn: "Y",
                pageNum: pageNum,
                pageSize: 10
              }
            axios.post(`${BASEURL}/v1/repair/repairmaster/findRepairRepairmaster`,param,{
                headers
            })
            .then(({data})=>{
                store.commit("isLoading",false);
                if(data.data == undefined || data.data == null) {
                    payload.me.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `알림`,
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                          text: data.message
                        },
                      })
                      return;
                }
                // data = data.data;
                let list = [];
                if(data.data != undefined && data.data != null) {
                    for(let i = 0; i<data.data.length; i++) {
                        list.push({
                            id : data.data[i]['id'],
                            createDt : moment(data.data[i]['ymd']).format('YYYY-MM-DD'),
                            shopName : data.data[i]['shopName'],
                            title : data.data[i]['title'] +(data.data[i]['secondTitle'] != undefined && data.data[i]['secondTitle'] != null ? data.data[i]['secondTitle'] : "") ,
                            totalPrice : data.data[i]['totalPrice'],
                            detail : true,
                        })
                    }
                }

                let pageNum = data.pageNum;
                let pageSize = data.pageSize;
                let pages = data.pages;
                let total = data.total;
                data = {
                    index : payload.index,
                    list : list,
                    pageNum : pageNum,
                    pageSize : pageSize,
                    pages : pages,
                    total : total
                }
                store.commit('subResult',data);
            })
            .catch((err)=> {
                store.commit("isLoading",false);
                console.log("에러 : ", err);
                Vue.prototype.$utils.exception(err);
            })
        }
        else if(payload.index == 3) {
            let data = {
                index : payload.index,
            };
            store.commit("isLoading",false);
            store.commit('subResult',data);
        }
        
    },
    saveFile : (store,payload) =>{
        let ymd = store.state.sub.battery.register.ymd;
        store.commit("isLoading",true);
        var formData = new FormData();
        formData.append("carId",store.state.result.car.id);
        formData.append("cls","ev");
        formData.append("files",store.state.sub.battery.register.fileInfo);
        formData.append("ymd",ymd.split("-").join(""));
        axios.post(`${BASEURL}/file/evdata/upload`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
                }
            }
        ).then(function({data}){
            store.commit("isLoading",false);
            if(data.data == undefined || data.data == null) {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message
                    },
                  })
                  return;
            }
            else {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: data.message
                    },
                })
            }
            store.commit('resetFile');
        })
        .catch((err)=> {
            store.commit("isLoading",false);
			Vue.prototype.$utils.exception(err);
        })

        
    },
    saveRepair : (store,payload) => {
        store.commit("isLoading",true);
        const headers =  {
            'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        }
        
        let repairdetail = [];
        let list = store.state.sub.repair.register.list;
        for(let i = 0; i<list.length; i++) {
            repairdetail.push({
                id : '',
                seq : i+1,
                partName : list[i]['partName'],
                price : list[i]['price'],
            })
        }
        
        let params = [ {
            id: "",
            vin: store.state.result.car.vin,
            carId: store.state.result.car.id,
            ymd: "",
            shopName: store.state.sub.repair.register.shopName,
            returnHash: "",
            useYn: "Y",
            repairdetail : repairdetail
        }];

        axios.post(`${BASEURL}/v1/repair/repairmaster/insertRepairRepairmaster`,params,{
            headers
        })
        .then(({data})=>{
            store.commit("isLoading",false);
            if(data.data == undefined || data.data == null) {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message
                    },
                  })
                  return;
            }
            else {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: data.message
                    },
                })
            }
            store.commit('resetRepair');
        })
        .catch((err)=> {
            store.commit("isLoading",false);
            console.log("에러 : ", err);
            Vue.prototype.$utils.exception(err);
        })
    },
    findCarBatteryInfo : (store,payload) =>{
        store.commit("isLoading",true);
        const headers =  {
            'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        }
        let param = {
            carKindCode: "",
            owerName: store.state.search.owerName,
            tokenUserId: "",
            vhcleNumber: store.state.search.vhcleNumber,
            vin: "",
            id: "",
            keyword: "",
            orderBy: "",
            useYn: "Y",
            pageNum: 1,
            pageSize: 10
        }

        axios.post(`${BASEURL}/v1/carinfo`,param,{
            headers
        })
        .then(({data})=>{
            store.commit("isLoading",false);
            if(data.data == undefined || data.data == null) {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message
                    },
                  })
                  return;
            }
            
            data = data.data[0];
            store.commit('findCarBatteryInfo',data);
        })
        .catch((err)=> {
            store.commit("isLoading",false);
            console.log("에러 : ", err);
			Vue.prototype.$utils.exception(err);
        })
    },
    findBatteryInfoDetail : (store,payload) => {
        store.commit("isLoading",true);
        const headers =  {
            'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        }

        let param = {
            carId : store.state.result.car.id,
            ymd: payload.ymd,
            pid: "",
            id: "",
            keyword: "",
            orderBy: "",
            useYn: "Y",
            pageNum: 1,
            pageSize: 10000
        }
        axios.post(`${BASEURL}/v1/ev/findEvhisdetailcarbymdpid`,param,{
            headers
        })
        .then(({data})=>{
            store.commit("isLoading",false);
            if(data.data == undefined || data.data == null) {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message
                    },
                  })
                  return;
            }

            let detailInfo = [];
            for(let i =0; i < data.data.length; i++) {
                detailInfo.push({
                    carId : param.carId,
                    ymd : param.ymd,
                    pid : data.data[i]['pid'],
                    detail : true
                })
            }
            data = {
                detailInfo : detailInfo,
                me : payload.me,
                modalId : payload.modalId,
                returnHash : payload.returnHash,
                blockNumber : payload.blockNumber,
                blockDt : payload.blockDt,
            };
            store.commit('findBatteryInfoDetail',data);
        })
        .catch((err)=> {
            store.commit("isLoading",false);
            console.log("에러 : ", err);
			Vue.prototype.$utils.exception(err);
        })
    },
    findBatteryInfoDetailHour : (store,payload) => {
        store.commit("isChartLoading",true);
        const headers =  {
            'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        }

        let param = {
            carId : payload.carId,
            ymd: payload.ymd,
            pid: payload.pid,
            hh : "",
            id: "",
            keyword: "",
            orderBy: "",
            useYn: "Y",
            pageNum: 1,
            pageSize: 10
        }
        axios.post(`${BASEURL}/v1/ev/findEvhisdetailcarbymdhhpid`,param,{
            headers
        })
        .then(({data})=>{
            store.commit("isChartLoading",false);
            if(data.data == undefined || data.data == null) {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message
                    },
                  })
                  return;
            }
            let hhList = [];
            if(data.data != undefined && data.data !=  null && data.data.length > 0) {
                for(let i = 0; i<data.data.length; i++){
                    hhList.push({
                        text : data.data[i]['hh']+"시",
                        carId : payload.carId,
                        ymd: payload.ymd,
                        pid: payload.pid,
                        hh : data.data[i]['hh']
                    });
                }
            }

            data = {
                hhList : hhList,
                me : payload.me,
                modalId : payload.modalId
            };

            store.commit('findBatteryInfoDetailHour',data);
            // let detailInfo = [];
            // for(let i =0; i < data.data.length; i++) {
            //     detailInfo.push({
            //         carId : param.carId,
            //         ymd : param.ymd,
            //         pid : data.data[i]['pid'],
            //         detail : true
            //     })
            // }
            // data = {
            //     detailInfo : detailInfo,
            //     me : payload.me,
            //     modalId : payload.modalId
            // };
            // store.commit('findBatteryInfoDetail',data);
        })
        .catch((err)=> {
            store.commit("isChartLoading",false);
            console.log("에러 : ", err);
			Vue.prototype.$utils.exception(err);
        })
    },
    findBatteryInfoChart : (store,payload) => {
        store.commit("isChartDataLoading",true);
        const headers =  {
            'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        }
        let param = {
            carId : payload.item.carId,
            ymd: payload.item.ymd,
            pid: payload.item.pid,
            hh : payload.item.hh,
            id: "",
            keyword: "",
            orderBy: "",
            useYn: "Y",
            pageNum: 1,
            pageSize: 3000
        }
        axios.post(`${BASEURL}/v1/ev/evhisdetail/findevevhisdetailbypid`,param,{
            headers
        })
        .then(({data})=>{
            if(data.data == undefined || data.data == null) {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message
                    },
                  })
                  return;
            }
            let chartData = data.data;
            data = {
                me : payload.me,
                chartData : chartData
            };
            store.commit('findBatteryInfoChart',data);
        })
        .catch((err)=> {
            store.commit("isChartDataLoading",false);
            console.log("에러 : ", err);
			Vue.prototype.$utils.exception(err);
        })
    },
    findrRepairInfoDetail : (store, payload) => {
        store.commit("isLoading",true);
        const headers =  {
            'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        }
        let total = payload.totalPrice;
        let param = {
            repairId: payload.repairId,
            id: "",
            keyword: "",
            orderBy: "",
            useYn: "Y",
            pageNum: 1,
            pageSize: 10
        }
        axios.post(`${BASEURL}/v1/repair/repairdetail/findRepairRepairdetail`,param,{
            headers
        })
        .then(({data})=>{
            store.commit("isLoading",false);
            if(data.data == undefined || data.data == null) {
                payload.me.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `알림`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message
                    },
                  })
                  return;
            }
            
            data = {
                detailInfo : data.data,
                total : total,
                me : payload.me,
                modalId : payload.modalId
            };
            store.commit('findrRepairInfoDetail',data);
        })
        .catch((err)=> {
            store.commit("isLoading",false);
            console.log("에러 : ", err);
			Vue.prototype.$utils.exception(err);
        })
    },
    
}