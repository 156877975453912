import moment from 'moment';
import Vue from 'vue'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const methods = {
    exception : (error) => {
        console.log(error.response)
        if (error.response) {
            console.log("??")
            console.log(error.response.data);
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `알림`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data == undefined || error.response.data == null ? error.response.message : error.response.data.message,
                },
              })
              if(error.response.data.code == "-9998") {
                    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                    localStorage.removeItem('userData')
                    router.push({ name: 'login' })
              }
        }
        else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `알림`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: '서버로부터 응답을 받지 못하였습니다',
                },
              });
        }
        else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `알림`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.message,
                },
              });
        }
    },
    // empty 체크
    isEmpty : (value) => {
        if(value == undefined || value == null || value == "") {
            return false;
        }
        return true;
    },
    // list key 값이 display 목록으로부터 item을 찾을때
    itemRender : (findValue , list) => {
        let index = list.findIndex(function(item){
            return item.value == findValue;
        });
        if(index > -1) {
            return list[index].display;
        }
        return "";
    },
    // list key 값이 text 목록으로부터 item을 찾을때
    itemTextRender : (findValue , list) => {
        let index = list.findIndex(function(item){
            return item.value == findValue;
        });
        if(index > -1) {
            return list[index].text;
        }
        return "";
    },
    // 콤마 기준 list 객체로부터 정렬처리할때
    itemCommaSplitRender : (findValue,list) => {
        if(findValue.length > 0){
            let renderText = [];
            findValue.split(",").forEach(element => {
                let index = list.findIndex(function(item){
                    return item.value == element;
                });
                if(index > -1) {
                    renderText.push(list[index].text);
                }
            });
            return renderText.toString();
        }
        return "";
    },
    // 연월일시분초 렌더링
    ymdhmsRender : (ymd,time) => {
        if(ymd != undefined && ymd != null && ymd !="") {
            ymd = ymd + time;
        }
        
        if(ymd != undefined && ymd != null && ymd != "") {
            ymd = moment(ymd, "YYYYMMDDHHmmss").format('YYYY-MM-DD HH:mm:ss');
        }
        return ymd;
    },
    // 콤마 렌더링
    commaRender : (value) => {
        if(value != undefined && value != null && value != ""){
            value = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }
        return value;
    },
    // 연락처 대시표시 렌더링
    phoneRender : (value) => {
        if(value != undefined && value != null && value != "") {
            value = value.replace(/[^0-9]/g,"").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,"$1-$2-$3").replace("--","-");
        }
        return value;
    },
    // 다음우편번호 주소 검색
    openAddress(me){
        new daum.Postcode({
            oncomplete: function(data) {
                me.sido = data.sido;
                me.sigungu = data.sigungu;
                me.zoneCode = data.zonecode;
                me.address = data.address;
            }
        }).open();
    },
    // 목록의 총합 구하기
    totalSum(list,sumKey){
        if(list != null && list.length > 0) {
            let sum = list.reduce((temp, item) => temp + Number(item[sumKey].toString().split(",").join("")),0); 
            if(sum > 0) {
                sum = sum.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }
            return sum;
        }
        return 0;            
    }
}



export default {
    install(Vue){
        Vue.prototype.$utils = methods
    }
}